import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Box } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { LoginForm } from '../sections/authentication/login';
import LOGO from '../asset/logo_black.png'
import Cover from '../asset/cover.png'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title={`Login | ${process.env.REACT_APP_PROJECTNAME}`}>
      {/* <AuthLayout /> */}

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 4 }}>
            <Box className="Logo_image_center">
              <img src={LOGO} style={{width:"50%"}} />
            </Box>
            <Typography className="heading_center" sx={{ color: 'text.secondary',fontWeight:"500",marginTop:"8px" }}>
              Enter your details below.
            </Typography>
          </Stack>

          <LoginForm />
          <Typography className="heading_center" variant="body2" sx={{ mt: 2 }}>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
